import { AppBar, Toolbar, Typography } from "@mui/material";
import { IconButton, Link } from "gatsby-theme-material-ui";
import * as React from "react";
import { useContext } from "react";
import { useState } from "react";

import LocalizationContext from "../context/LocalizationContext";
import GuideModal from "./GuideModal";
import FirstAidIcon from "./icons/FirstAidIcon";
import InfoIcon from "./icons/InfoIcon";
import LanguageIcon from "./icons/LanguageIcon";

function Banner(): JSX.Element {
  const { iso, toggleLanguage } = useContext(LocalizationContext);

  const [guideModal, setGuideModal] = useState(false);

  return (
    <AppBar>
      <Toolbar>
        <Link
          to="/roadmap"
          sx={{
            lineHeight: "15px",
            mr: 2,
          }}
        >
          <FirstAidIcon />
        </Link>
        <Link
          to="/roadmap"
          underline="none"
          sx={{
            flexGrow: 1,
          }}
        >
          <Typography
            variant="h1"
            fontSize="20px"
            color="common.white"
            fontWeight={500}
          >
            Pohotovosti Praha
          </Typography>
        </Link>
        <IconButton
          onClick={() => setGuideModal(true)}
          color="secondary"
          aria-label="info"
        >
          <InfoIcon />
        </IconButton>
        <IconButton onClick={toggleLanguage} aria-label="language switch">
          <LanguageIcon language={iso} />
        </IconButton>
        <GuideModal onClose={() => setGuideModal(false)} open={guideModal} />
      </Toolbar>
    </AppBar>
  );
}

export default Banner;
