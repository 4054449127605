import { graphql, useStaticQuery } from "gatsby";

import { GuideModalQuery } from "../graphqlTypes";
import useLocalization from "../hooks/useLocalization";

const guideModalQuery =
(): GuideModalQuery["allLocalizableJson"]["edges"][number]["node"]["modal"] => {
  const { iso } = useLocalization();

  const { allLocalizableJson } = useStaticQuery<GuideModalQuery>(graphql`
      query GuideModal {
          allLocalizableJson {
              edges {
                  node {
                      modal {
                          guideModal {
                              items {
                                  description
                                  label
                              }
                              highlightedInfo
                              heading
                          }
                          common {
                              close
                          }
                      }
                      language
                  }
              }
          }
      }
  `);

  return allLocalizableJson.edges
    .filter((translation) => translation.node.language === iso)
    .map((translation) => translation.node.modal)[0];
};

export default guideModalQuery;
