import * as React from "react";

import { IconProps } from "../../utils/common";

const InfoIcon: React.FC<IconProps> = ({
  width = "24",
  height = "25",
  fill = "white",
  fillOpacity = "0.74",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 9.58594H13V7.58594H11V9.58594ZM11 17.5859H13V11.5859H11V17.5859ZM11.99 22.5859C6.47 22.5859 2 18.1059 2 12.5859C2 7.06594 6.47 2.58594 11.99 2.58594C17.52 2.58594 22 7.06594 22 12.5859C22 18.1059 17.52 22.5859 11.99 22.5859ZM12 4.58594C7.58 4.58594 4 8.16594 4 12.5859C4 17.0059 7.58 20.5859 12 20.5859C16.42 20.5859 20 17.0059 20 12.5859C20 8.16594 16.42 4.58594 12 4.58594Z"
        fill={fill}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
};

export default InfoIcon;
