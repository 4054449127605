import * as React from "react";

import CarriageIcon from "./icons/CarriageIcon";
import HospitalIcon from "./icons/HospitalIcon";
import PillsIcon from "./icons/PillsIcon";
import ToothIcon from "./icons/ToothIcon";

interface Props {
  type: "general" | "pediatrician" | "dentist" | "pharmacy";
  width?: string;
  height?: string;
  fill?: string;
}

export default function LPSIcon({
  type,
  width,
  height,
  fill,
}: Props): JSX.Element {
  switch (type) {
    case "general":
      return (
        <HospitalIcon
          width={width ?? "24"}
          height={height ? height : "25"}
          fill={fill}
        />
      );
    case "pediatrician":
      return (
        <CarriageIcon
          width={width ?? "22"}
          height={height ? height : "23"}
          fill={fill}
        />
      );
    case "dentist":
      return (
        <ToothIcon
          width={width ?? "20"}
          height={height ? height : "21"}
          fill={fill}
        />
      );
    case "pharmacy":
      return (
        <PillsIcon
          width={width ?? "21"}
          height={height ? height : "24"}
          fill={fill}
        />
      );
    default:
      return (
        <HospitalIcon
          width={width ?? "24"}
          height={height ? height : "25"}
          fill={fill}
        />
      );
  }
}
