import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          borderRadius: "30px",
          paddingRight: 0,
          paddingLeft: "15px",
          filter: "drop-shadow(0 3px 2px #00000070)",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#BA2525",
      light: "#E66A6A",
    },
    secondary: {
      main: "#BA2525",
    },
    info: {
      main: "#4098D7",
    },
    success: {
      main: "#FFEEEE",
      light: "#FFFFFF",
    },
    background: {
      primary: "#BA2525",
      listItem: "#FAF9F7",
      checkedTag: "#FFEEEE",
      dialogHighlight: "#FFEEEE",
      publicTransport: "#4098D7",
    },
  },
  shape: {
    borderRadius: 5,
  },
});

export default theme;
