import * as React from "react";
import { ReactNode, useContext, useState } from "react";
import { ViewState } from "react-map-gl";

import { LatLng } from "../utils/common";
import getLatLng from "../utils/getLatLng";
import { useMapBoxSDK } from "./MapSDKContext";
import StoreContext from "./StoreContext";

interface Props {
  children: ReactNode;
}

interface ViewStateWH extends ViewState {
  width?: number;
  height?: number;
}

export function MapProvider({ children }: Props): JSX.Element {
  const { listData, updateListData } = useContext(StoreContext);
  const [satellite, setSatellite] = useState(false);
  const [viewState, setViewState] = useState<ViewStateWH>({
    width: 0,
    height: 0,
    longitude: 14.41854,
    latitude: 50.073658,
    zoom: 12,
  });
  const { matrixClient } = useMapBoxSDK();

  async function setDistances(currentPosition: LatLng) {
    const targets = listData
      .map((item) => getLatLng(item.latLng))
      .map((latLng) => `;${latLng.lng},${latLng.lat}`);
    const response = await matrixClient
      .getMatrix({
        profile: "driving",
        points: [
          { coordinates: [currentPosition.lng, currentPosition.lat] },
          ...listData
            .map(({ latLng }) => getLatLng(latLng))
            .map((latLng) => ({ coordinates: [latLng.lng, latLng.lat] })),
        ],
        destinations: [...Array(targets.length + 1).keys()].slice(1),
        sources: [0],
      })
      .send();

    updateListData(
      listData.map((row, index) => ({
        ...row,
        distanceCar: response.body.durations[0][index] / 60,
      }))
    );
  }

  const toggleSatellite = () => setSatellite(!satellite);

  return (
    <MapContext.Provider
      value={{
        viewState,
        setViewState,
        setDistances,
        satellite,
        toggleSatellite,
      }}
    >
      {children}
    </MapContext.Provider>
  );
}

interface IMapProvider {
  viewState: ViewState;
  setViewState: (viewport) => void;
  setDistances: (LatLng) => void;
  satellite: boolean;
  toggleSatellite: () => void;
}

const MapContext = React.createContext<IMapProvider>(null);

export default MapContext;
