import { SvgIcon } from "@mui/material";
import * as React from "react";

function PillsIconOutlined(props): JSX.Element {
  return (
    <SvgIcon {...props}>
      <path d="M 21.9 16.3 C 23.8 14.3 23.8 11.7 21.9 9.8 L 14.6 2.1 C 12.8 0.2 10.3 0.2 8.5 2.1 C 6.6 4.1 6.6 6.7 8.4 8.6 L 15.8 16.3 C 17.6 18.2 20 18.2 21.9 16.3 Z M 9.7 7.5 C 8.5 6.2 8.4 4.5 9.6 3.3 C 10.8 2.1 12.4 2.1 13.6 3.4 L 16.6 6.5 L 12.6 10.7 L 9.7 7.5 Z M 20.8 15.1 C 19.6 16.3 18 16.3 16.8 15 L 13.8 11.8 L 17.7 7.7 L 20.7 10.9 C 21.9 12.1 21.9 13.8 20.8 15.1 Z M 5.5 22 C 8.4 22 10.8 19.5 10.8 16.4 C 10.8 13.3 8.4 10.8 5.5 10.8 C 2.5 10.8 0.1 13.3 0.1 16.4 C 0.1 19.5 2.5 22 5.5 22 Z M 5.5 12.4 C 7.3 12.4 8.8 13.8 9.1 15.5 H 1.8 C 2.1 13.8 3.7 12.4 5.5 12.4 Z M 5.5 20.3 C 3.6 20.3 2.1 19 1.8 17.2 H 9.1 C 8.8 19 7.3 20.3 5.5 20.3 Z" />
    </SvgIcon>
  );
}

export default PillsIconOutlined;
