import { useContext } from "react";

import LocalizationContext, {
  ILocalizationProvider,
} from "../context/LocalizationContext";

/**
 * Hook jako zkratka na LocalizationContext
 */
function useLocalization(): ILocalizationProvider {
  return useContext(LocalizationContext);
}

export default useLocalization;
