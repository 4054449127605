import { IMarker, LPSDataItem } from "./common";
import getLatLng from "./getLatLng";
import { getLPSState } from "./getLPSState";
import { getLPSType } from "./getLPSType";
import { checkClosed } from "./timeUtils";

export default function (item: LPSDataItem): IMarker {
  return {
    id: item.id,
    lat: getLatLng(item.latLng).lat,
    lng: getLatLng(item.latLng).lng,
    state: getLPSState(checkClosed(item.openDaily, item.openHoliday), false),
    type: getLPSType(item.type),
  };
}
