export default function (latLng: string): { lat: number; lng: number } {
  if (latLng) {
    const latLngArr = latLng.split(",");
    return {
      lat: parseFloat(latLngArr[0].replace(/["/]/g, "")),
      lng: parseFloat(latLngArr[1].replace(/["/]/g, "")),
    };
  }
  return {
    lat: null,
    lng: null,
  };
}
