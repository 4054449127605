import { State } from "./common";

export const getLPSState = (closed: boolean, highlighted: boolean): State => {
  if (highlighted) {
    return "highlighted";
  } else if (closed) {
    return "closed";
  }
  return "open";
};
