import { graphql, useStaticQuery } from "gatsby";

import { OpenHoursTranslationQuery } from "../graphqlTypes";
import useLocalization from "../hooks/useLocalization";

const openHoursTranslationQuery =
  (): OpenHoursTranslationQuery["allLocalizableJson"]["edges"][number]["node"]["openHours"] => {
    const { iso } = useLocalization();

    const { allLocalizableJson } =
      useStaticQuery<OpenHoursTranslationQuery>(graphql`
        query OpenHoursTranslation {
          allLocalizableJson {
            edges {
              node {
                language
                openHours {
                  closedUntil
                  mondayToFriday
                  weekend
                  openUntil
                  openNonstop
                }
              }
            }
          }
        }
      `);

    return allLocalizableJson.edges
      .filter((translation) => translation.node.language === iso)
      .map((translation) => translation.node.openHours)[0];
  };

export default openHoursTranslationQuery;
