import * as React from "react";

import { IconProps } from "../../utils/common";

const CarriageIcon: React.FC<IconProps> = ({
  width = "37",
  height = "37",
  fill = "#E66A6A",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3125 36.5C11.5337 36.5 10.7725 36.2691 10.1249 35.8364C9.47742 35.4038 8.97275 34.7888 8.67473 34.0693C8.37671 33.3498 8.29873 32.5581 8.45066 31.7943C8.60259 31.0305 8.9776 30.3289 9.52827 29.7783C10.0789 29.2276 10.7805 28.8526 11.5443 28.7007C12.3081 28.5487 13.0998 28.6267 13.8193 28.9247C14.5388 29.2227 15.1538 29.7274 15.5864 30.3749C16.0191 31.0225 16.25 31.7837 16.25 32.5625C16.25 33.6068 15.8352 34.6083 15.0967 35.3467C14.3583 36.0852 13.3568 36.5 12.3125 36.5V36.5ZM12.3125 30.875C11.9787 30.875 11.6525 30.974 11.375 31.1594C11.0975 31.3448 10.8812 31.6084 10.7535 31.9167C10.6257 32.2251 10.5923 32.5644 10.6574 32.8917C10.7225 33.2191 10.8833 33.5197 11.1193 33.7557C11.3553 33.9917 11.6559 34.1525 11.9833 34.2176C12.3106 34.2827 12.6499 34.2493 12.9583 34.1215C13.2666 33.9938 13.5302 33.7775 13.7156 33.5C13.901 33.2225 14 32.8963 14 32.5625C14 32.1149 13.8222 31.6857 13.5057 31.3693C13.1893 31.0528 12.7601 30.875 12.3125 30.875Z"
        fill={fill}
      />
      <path
        d="M26.9375 36.5C26.1587 36.5 25.3975 36.2691 24.7499 35.8364C24.1024 35.4038 23.5977 34.7888 23.2997 34.0693C23.0017 33.3498 22.9237 32.5581 23.0757 31.7943C23.2276 31.0305 23.6026 30.3289 24.1533 29.7783C24.7039 29.2276 25.4055 28.8526 26.1693 28.7007C26.9331 28.5487 27.7248 28.6267 28.4443 28.9247C29.1638 29.2227 29.7788 29.7274 30.2114 30.3749C30.6441 31.0225 30.875 31.7837 30.875 32.5625C30.875 33.6068 30.4602 34.6083 29.7217 35.3467C28.9833 36.0852 27.9818 36.5 26.9375 36.5ZM26.9375 30.875C26.6037 30.875 26.2775 30.974 26 31.1594C25.7225 31.3448 25.5062 31.6084 25.3785 31.9167C25.2507 32.2251 25.2173 32.5644 25.2824 32.8917C25.3475 33.2191 25.5083 33.5197 25.7443 33.7557C25.9803 33.9917 26.2809 34.1525 26.6083 34.2176C26.9356 34.2827 27.2749 34.2493 27.5833 34.1215C27.8916 33.9938 28.1552 33.7775 28.3406 33.5C28.526 33.2225 28.625 32.8963 28.625 32.5625C28.625 32.1149 28.4472 31.6857 28.1307 31.3693C27.8143 31.0528 27.3851 30.875 26.9375 30.875V30.875Z"
        fill={fill}
      />
      <path
        d="M1.625 6.125C2.22174 6.125 2.79403 6.36205 3.21599 6.78401C3.63795 7.20597 3.875 7.77826 3.875 8.375V12.875H6.125V8.375C6.125 7.18153 5.65089 6.03693 4.80698 5.19302C3.96307 4.34911 2.81847 3.875 1.625 3.875C1.32663 3.875 1.04048 3.99353 0.829505 4.2045C0.618526 4.41548 0.5 4.70163 0.5 5C0.5 5.29837 0.618526 5.58452 0.829505 5.79549C1.04048 6.00647 1.32663 6.125 1.625 6.125V6.125Z"
        fill={fill}
      />
      <path
        d="M23 0.5C22.7016 0.5 22.4155 0.618526 22.2045 0.829505C21.9935 1.04048 21.875 1.32663 21.875 1.625V12.875H36.4426C36.1584 9.50376 34.6196 6.36186 32.1305 4.07046C29.6414 1.77906 26.3832 0.504941 23 0.5Z"
        fill={fill}
      />
      <path
        d="M3.875 15.125V18.5C3.87738 20.5878 4.70783 22.5895 6.18417 24.0658C7.6605 25.5422 9.66215 26.3726 11.75 26.375H28.625C30.7128 26.3726 32.7145 25.5422 34.1908 24.0658C35.6672 22.5895 36.4976 20.5878 36.5 18.5V15.125H3.875Z"
        fill={fill}
      />
    </svg>
  );
};

export default CarriageIcon;
