import { Box, Typography } from "@mui/material";
import * as React from "react";

import openHoursTranslationQuery from "../staticQueries/openHoursTranslationQuery";

interface Props {
  nonstop: boolean;
  closed: boolean;
  openingHours: string;
}

function OpenInfo({ nonstop, closed, openingHours }: Props): JSX.Element {
  const { openUntil, closedUntil, openNonstop } = openHoursTranslationQuery();

  return (
    <Box>
      {nonstop ? (
        <Typography color="warning.light" fontSize="12px">
          {openNonstop}
        </Typography>
      ) : (
        <Typography
          color={closed ? "grey.500" : "warning.light"}
          fontSize="12px"
        >
          {closed ? closedUntil : openUntil}
          &nbsp;
          {openingHours}
        </Typography>
      )}
    </Box>
  );
}

export default OpenInfo;
