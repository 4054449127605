import * as React from "react";

import { IconProps } from "../../utils/common";

function MarkerPlain({
  width = "40",
  height = "52",
  fill = "#BA2525",
}: IconProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 0.75C23.4961 0.75 29.1406 6.27734 29.1406 14.5586C29.1406 18.5625 27.5 22.8008 25.8984 26.1602C23.8672 30.5742 20.625 35.3398 17.7539 39.7344C16.9922 40.9062 16.0547 41.4922 15 41.4922C13.9453 41.4922 13.0078 40.9062 12.2461 39.7344C9.31641 35.2617 6.03516 30.3984 3.96484 25.9062C2.44141 22.6445 0.859375 18.5039 0.859375 14.5586C0.859375 6.27734 6.50391 0.75 15 0.75Z"
        fill={fill}
      />
    </svg>
  );
}

export default MarkerPlain;
