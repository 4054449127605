/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import "vanilla-cookieconsent";
import "./styles/LPSCookieConsent.css";

import { useLocation } from "@reach/router";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import * as React from "react";
import { useContext, useEffect, useRef } from "react";
import { useCookies } from "react-cookie";

import LocalizationContext from "../context/LocalizationContext";

export default function LPSCookieConsent(): JSX.Element {
  const { iso } = useContext(LocalizationContext);
  const location = useLocation();
  const cc = useRef<any>(null);
  const [cookies, setCookie, removeCookie] = useCookies();

  useEffect(() => {
    const win = window as Window &
      typeof globalThis & {
        initCookieConsent: () => any;
        CookieConsent: any;
      };

    if (win.CookieConsent) {
      win.CookieConsent.updateLanguage(iso);
    }
  }, [iso]);

  useEffect(() => {
    const win = window as Window &
      typeof globalThis & {
        initCookieConsent: () => any;
        CookieConsent: any;
      };

    if (!win.initCookieConsent) {
      return;
    }
    cc.current = win.initCookieConsent();
    win.CookieConsent = cc.current;

    cc.current.run({
      current_lang: iso,
      autoclear_cookies: true, // default: false
      cookie_name: "lps-cookieconsent", // default: 'cc_cookie'
      // remove_cookie_tables: true,

      gui_options: {
        consent_modal: {
          layout: "cloud", // box,cloud,bar
          position: "bottom center", // bottom,middle,top + left,right,center
          transition: "slide", // zoom,slide
        },
        settings_modal: {
          layout: "box", // box,bar
          // position: 'left', // right,left (available only if bar layout selected)
          transition: "slide", // zoom,slide
        },
      },

      onFirstAction: (
        user_preferences: { accepted_categories: string[] },
        cookie: never
      ) => {
        for (const level of user_preferences.accepted_categories) {
          // analytics category
          if (level === "analytics") {
            // ga enable
            initializeAndTrack(location);
            setCookie("gatsby-gdpr-google-analytics", "true");
          } else {
            removeCookie("gatsby-gdpr-google-analytics");
          }
        }
      },

      onAccept: (cookie: { categories: string[] }) => {
        for (const level of cookie.categories) {
          // analytics category
          if (level === "analytics") {
            // ga enable
            if (!cookies["gatsby-gdpr-google-analytics"]) {
              initializeAndTrack(location);
            }
            setCookie("gatsby-gdpr-google-analytics", "true");
          } else {
            removeCookie("gatsby-gdpr-google-analytics");
          }
        }
      },

      onChange: (cookie: never, changed_preferences: string[]) => {
        // If analytics category's status was changed
        if (changed_preferences.indexOf("analytics") > -1) {
          // If analytics category is disabled
          if (!cc.current.allowedCategory("analytics")) {
            removeCookie("gatsby-gdpr-google-analytics");
          } else {
            initializeAndTrack(location);
            setCookie("gatsby-gdpr-google-analytics", "true");
          }
        }
      },

      languages: {
        cs: {
          consent_modal: {
            title: "Používáme cookies",
            description:
              'Díky využití souborů cookies lépe chápeme, co vás zajímá a můžeme vám připravit ten nejlepší uživatelský zážitek. Dejte nám vědět, zda souhlasíte s jejich použitím, nebo si je <button type="button" data-cc="c-settings" class="cc-link">přizpůsobte podle svých představ</button>.',
            primary_btn: {
              text: "Povolit vše",
              role: "accept_all", // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: "Povolit nezbytné",
              role: "accept_necessary", // 'settings' or 'accept_necessary'
            },
          },
          settings_modal: {
            title: "Přizpůsobit nastavení cookies",
            save_settings_btn: "Uložit nastavení",
            accept_all_btn: "Povolit vše",
            reject_all_btn: "Povolit nezbytné",
            close_btn_label: "Zavřít",
            blocks: [
              {
                title: "Nezbytně nutné cookies",
                description:
                  "Tyto cookies pomáhají, aby webová stránka byla použitelná a fungovala správně. Ve výchozím nastavení jsou povoleny a nelze je zakázat.",
                toggle: {
                  value: "necessary",
                  enabled: true,
                  readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                },
              },
              {
                title: "Statistika",
                description:
                  "Díky těmto cookies víme, kam u nás chodíte nejraději a máme statistiky o celkové návštěvnosti našich stránek.",
                toggle: {
                  value: "analytics", // there are no default categories => you specify them
                  enabled: false,
                  readonly: false,
                },
              },
            ],
          },
        },
        en: {
          consent_modal: {
            title: "We use cookies",
            description:
              'Thanks to the use of cookies, we better understand what interests you and we can prepare the best user experience for you. Let us know if you agree with their use or <button type="button" data-cc="c-settings" class="cc-link">customize them to your liking</button>.',
            primary_btn: {
              text: "Accept all",
              role: "accept_all", // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: "Accept necessary",
              role: "accept_necessary", // 'settings' or 'accept_necessary'
            },
          },
          settings_modal: {
            title: "Customize cookie settings",
            save_settings_btn: "Save settings",
            accept_all_btn: "Accept all",
            reject_all_btn: "Accept necessary",
            close_btn_label: "Close",
            blocks: [
              {
                title: "Necessary cookies",
                description:
                  "These cookies help to make the website usable and work properly. By default, they are enabled and cannot be disabled.",
                toggle: {
                  value: "necessary",
                  enabled: true,
                  readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                },
              },
              {
                title: "Statistics",
                description:
                  "Thanks to these cookies, we know where you like to go with us the most and we have statistics on the total traffic to our website.",
                toggle: {
                  value: "analytics", // there are no default categories => you specify them
                  enabled: false,
                  readonly: false,
                },
              },
            ],
          },
        },
      },
    });
  }, []);

  return <div></div>;
}
