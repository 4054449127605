import { graphql, useStaticQuery } from "gatsby";

import { InfoIconTranslationQuery } from "../graphqlTypes";
import useLocalization from "../hooks/useLocalization";

function useInfoIconTranslationQuery(): InfoIconTranslationQuery["allLocalizableJson"]["edges"][number]["node"] {
  const { iso } = useLocalization();

  const { allLocalizableJson } =
    useStaticQuery<InfoIconTranslationQuery>(graphql`
      query InfoIconTranslation {
        allLocalizableJson {
          edges {
            node {
              infoIcon
              language
            }
          }
        }
      }
    `);

  return allLocalizableJson.edges
    .filter((translation) => translation.node.language === iso)
    .map((translation) => translation.node)[0];
}

export default useInfoIconTranslationQuery;
