export const LPSTypes = ["general", "pediatrician", "dentist", "pharmacy"];

export const LPSTypesObj = {
  general: "general",
  pediatrician: "pediatrician",
  dentist: "dentist",
  pharmacy: "pharmacy",
};

export const LPSIconLabels = {
  general: "Všeobecná",
  pediatrician: "Dětská",
  dentist: "Zubní",
  pharmacy: "Lékárna",
};

export const mbxDrivingDistanceEndpoint =
  "https://api.mapbox.com/directions-matrix/v1/mapbox/driving/";
