import * as React from "react";

import { IconProps } from "../../utils/common";

const ToothIcon: React.FC<IconProps> = ({
  width = "33",
  height = "33",
  fill = "#E66A6A",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.25 6C32.25 3.075 29.925 0.75 27 0.75C25.875 0.75 23.7 1.275 20.55 2.325C17.925 3.15 15.075 3.15 12.45 2.325C9.3 1.275 7.125 0.75 6 0.75C3.075 0.75 0.75 3.075 0.75 6C0.75 8.325 2.025 12.675 2.25 13.575L0.75 28.65C0.75 30.6 2.4 32.25 4.35 32.25C5.4 32.25 6.45 31.65 7.425 30.6L13.2 23.775C13.95 22.875 15.075 21.825 16.5 21.825C17.625 21.825 18.675 22.425 19.8 23.775L25.575 30.6C26.1 31.2 27.15 32.325 28.65 32.325C30.675 32.325 32.25 30.675 32.25 28.65L30.75 13.65C30.975 12.675 32.25 8.325 32.25 6ZM21.15 9.9C19.65 10.275 18.075 10.425 16.5 10.425C14.925 10.425 13.35 10.275 11.85 9.9C11.475 9.825 11.175 9.375 11.325 9C11.4 8.625 11.85 8.325 12.225 8.475C13.65 8.775 15.075 9 16.575 9C18 9 19.5 8.85 20.925 8.475C21.3 8.4 21.75 8.625 21.825 9C21.9 9.375 21.6 9.825 21.15 9.9Z"
        fill={fill}
      />
    </svg>
  );
};

export default ToothIcon;
