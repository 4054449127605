import * as React from "react";
import { useContext, useEffect, useState } from "react";
import ReactMapGL, { GeolocateControl } from "react-map-gl";

import MapContext from "../context/MapContext";
import StoreContext from "../context/StoreContext";
import { IMarker } from "../utils/common";
import MarkerWrapper from "./MarkerWrapper";

const geolocateStyle = {
  bottom: 13,
  right: 0,
  margin: 10,
  zIndex: 2,
};

const positionOptions = { enableHighAccuracy: true };

function Map(): JSX.Element {
  const { viewState, setViewState, setDistances, satellite } =
    useContext(MapContext);
  const [currentLocation, setCurrentLocation] = useState();

  useEffect(() => {
    const currentViewState = {
      ...viewState,
      width: window.innerWidth,
      height: window.innerHeight,
    };
    setViewState(currentViewState);
  }, []);

  useEffect(() => {
    if (currentLocation)
      setDistances({
        lat: currentLocation.coords.latitude,
        lng: currentLocation.coords.longitude,
      });
  }, [currentLocation?.coords?.latitude, currentLocation?.coords?.longitude]);
  // v deps jsou naschval vyjmenovane long a lat, aby nedoslo k rerenderu kdyz se hodnota nezmeni

  const [listedMarkers, setListedMarkers] = React.useState([]);

  const { markers, filteredMarkers } = useContext(StoreContext);

  const listMarkers = (markers: IMarker[]) =>
    markers.map((item, key) => {
      return (
        <MarkerWrapper
          key={key}
          id={item.id}
          lat={item.lat}
          lng={item.lng}
          type={item.type}
          state={item.state}
        />
      );
    });

  useEffect(() => {
    setListedMarkers(listMarkers(filteredMarkers));
  }, [filteredMarkers]);

  useEffect(() => {
    setListedMarkers(listMarkers(markers));
  }, [markers]);

  const geolocateControlRef = React.useCallback(async (ref) => {
    if (ref) {
      await new Promise((resolve) => {
        setTimeout(resolve, 1000);
      });
      // Activate as soon as the control is loaded
      ref.trigger();
    }
  }, []);

  return (
    <ReactMapGL
      {...viewState}
      onMove={(e) => setViewState(e.viewState)}
      mapboxAccessToken={process.env.MAPBOX_API_ACCESS_TOKEN}
      mapStyle={
        satellite
          ? process.env.MAPBOX_MAP_STYLE_SATELLITE
          : process.env.MAPBOX_MAP_STYLE
      }
      maxPitch={0}
      style={{ width: "100vw", height: "100vh" }}
    >
      <GeolocateControl
        ref={geolocateControlRef}
        style={geolocateStyle}
        positionOptions={positionOptions}
        onGeolocate={setCurrentLocation}
        trackUserLocation
      />
      {listedMarkers}
    </ReactMapGL>
  );
}

export default Map;
