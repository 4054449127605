import { Stack, Typography } from "@mui/material";
import * as React from "react";
import { ReactNode } from "react";

interface Props {
  label: string;
  labelBold?: boolean;
  description?: string;
  children: ReactNode;
}
function GuideModalItem({
  label,
  labelBold,
  description,
  children,
}: Props): JSX.Element {
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      {children}
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={0}
      >
        <Typography fontSize="16px" fontWeight={labelBold && "bold"}>
          {label}
        </Typography>
        <Typography fontSize="14px">{description}</Typography>
      </Stack>
    </Stack>
  );
}

export default GuideModalItem;
