import * as React from "react";

import { IconProps } from "../../utils/common";

const PillsIcon: React.FC<IconProps> = ({
  width = "36",
  height = "30",
  fill = "#E66A6A",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9541 15.9346L26.4209 7.46777L21.6309 2.67773C19.0088 0.0410156 15.625 0.0117188 13.0469 2.56055C10.498 5.09473 10.5273 8.50781 13.1641 11.1445L17.9541 15.9346ZM19.1553 17.1504L23.9746 21.9551C26.5967 24.5771 29.9805 24.6211 32.5586 22.0576C35.1074 19.5234 35.0781 16.1104 32.4414 13.4883L27.6367 8.66895L19.1553 17.1504ZM0.551758 21.4277H15.4492C15.0537 17.6777 11.8604 14.7627 8.00781 14.7627C4.14062 14.7627 0.947266 17.6777 0.551758 21.4277ZM0.551758 23.0977C0.947266 26.8623 4.12598 29.7773 8.00781 29.7773C11.875 29.7773 15.0391 26.8477 15.4492 23.0977H0.551758Z"
        fill={fill}
      />
    </svg>
  );
};

export default PillsIcon;
