import * as React from "react";

interface Props {
  language: string;
}

function LanguageIcon({ language = "cs" }: Props): JSX.Element {
  if (language === "en") {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1281_9282)">
          <path
            d="M41 12H13L-5 -6H39C40.105 -6 41 -5.105 41 -4V12Z"
            fill="#E6E6E6"
          />
          <path
            d="M41 28C41 29.105 40.105 30 39 30H-5L13 12H41V28Z"
            fill="#D7141A"
          />
          <path
            d="M-5 -6C-6.104 -6 -7 -5.104 -7 -4V12V28C-7 29.104 -6.104 30 -5 30L13 12L-5 -6Z"
            fill="#11457E"
          />
        </g>
        <defs>
          <clipPath id="clip0_1281_9282">
            <rect width="24" height="24" rx="12" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (language === "cs") {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1281_9282)">
          <path
            d="M23 0H1C0.734784 0 0.48043 0.140476 0.292893 0.390524C0.105357 0.640573 0 0.979711 0 1.33333L0 22.6667C0 23.0203 0.105357 23.3594 0.292893 23.6095C0.48043 23.8595 0.734784 24 1 24H23C23.2652 24 23.5196 23.8595 23.7071 23.6095C23.8946 23.3594 24 23.0203 24 22.6667V1.33333C24 0.979711 23.8946 0.640573 23.7071 0.390524C23.5196 0.140476 23.2652 0 23 0V0Z"
            fill="#002781"
          />
          <path
            d="M24 1.33333C24 0.979711 23.8946 0.640573 23.7071 0.390524C23.5196 0.140476 23.2652 0 23 0H20.4195L14 6.584V0H10V6.584L3.5805 0H1C0.734784 0 0.48043 0.140476 0.292893 0.390524C0.105357 0.640573 0 0.979711 0 1.33333L0 3.05733L6.1195 9.33333H0V14.6667H6.1195L0 20.9433V22.6667C0 23.0203 0.105357 23.3594 0.292893 23.6095C0.48043 23.8595 0.734784 24 1 24H3.5805L10 17.416V24H14V17.416L20.4195 24H23C23.2652 24 23.5196 23.8595 23.7071 23.6095C23.8946 23.3594 24 23.0203 24 22.6667V20.9433L17.8805 14.6667H24V9.33333H17.8805L24 3.05733V1.33333Z"
            fill="#E5E5E5"
          />
          <path
            d="M24 10.6667H13V0H11V10.6667H0V13.3333H11V24H13V13.3333H24V10.6667Z"
            fill="#D10D24"
          />
          <path
            d="M10 14.4824L0.791016 23.9704C0.859728 23.9899 0.929762 24 1.00002 24.0004H2.19302L10 15.9571V14.4824Z"
            fill="#D10D24"
          />
          <path
            d="M14 9.518L23.209 0.0300001C23.1403 0.0105136 23.0703 0.000461075 23 0H21.807L14 8.044V9.518Z"
            fill="#D10D24"
          />
          <path
            d="M9.003 9.33367L0.3075 0.375C0.210319 0.498796 0.132966 0.647573 0.0801145 0.812344C0.027263 0.977115 9.42173e-06 1.15446 0 1.33367L0 1.53367L7.572 9.33367H9.003Z"
            fill="#D10D24"
          />
          <path
            d="M14.9969 14.667L23.6924 23.6257C23.7896 23.5019 23.867 23.3531 23.9198 23.1883C23.9727 23.0235 23.9999 22.8462 23.9999 22.667V22.467L16.4279 14.667H14.9969Z"
            fill="#D10D24"
          />
        </g>
        <defs>
          <clipPath id="clip0_1281_9282">
            <rect width="24" height="24" rx="12" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
}

export default LanguageIcon;
