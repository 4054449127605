const currentDate = new Date();

const checkWeekend = (day: number): boolean => day === 6 || day === 0;

const normalizeTime = (time: string) => {
  let timeParts = time.split(":");
  timeParts = timeParts.map((item) => (item.length === 2 ? item : "0" + item));
  return timeParts.join(":");
};

export const isInRange = (time: string, range: string[]): boolean => {
  if (range[0] < range[1]) {
    return time >= range[0] && time <= range[1];
  } else if (range[0] > range[1]) {
    return time >= range[0] || time <= range[1];
  } else {
    return time === range[0];
  }
};

const checkFit = (openTime: string, currentDate: Date): boolean => {
  const range = openTime.split("-");
  const time = `${currentDate.getHours()}:${currentDate.getMinutes()}`;
  return isInRange(normalizeTime(time), [
    normalizeTime(range[0]),
    normalizeTime(range[1]),
  ]);
};

export const checkClosed = (
  openDaily: string,
  openWeekend: string
): boolean => {
  if (checkWeekend(currentDate.getDay())) {
    return !checkFit(openWeekend, currentDate);
  } else {
    return !checkFit(openDaily, currentDate);
  }
};

export const getOpeningHours = (
  openDaily: string,
  openWeekend: string
): string => {
  const closed = checkClosed(openDaily, openWeekend);

  if (checkWeekend(currentDate.getDay())) {
    return closed ? openWeekend.split("-")[0] : openWeekend.split("-")[1];
  } else {
    return closed ? openDaily.split("-")[0] : openDaily.split("-")[1];
  }
};

export const checkNonstop = (
  openDaily: string,
  openWeekend: string
): boolean => {
  if (checkWeekend(currentDate.getDay())) {
    return openWeekend === "00:00-24:00";
  } else {
    return openDaily === "00:00-24:00";
  }
};
