import { Stack, Typography } from "@mui/material";
import * as React from "react";

import OpenInfo from "./OpenInfo";

interface Props {
  typeLabel: string;
  nonstop: boolean;
  closed: boolean;
  openingHours: string;
}

function MarkerFlag({
  typeLabel,
  nonstop,
  closed,
  openingHours,
}: Props): JSX.Element {
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={0}
    >
      <Typography fontSize="12px" fontWeight="bold">
        {typeLabel}
      </Typography>
      <OpenInfo nonstop={nonstop} closed={closed} openingHours={openingHours} />
    </Stack>
  );
}

export default MarkerFlag;
