import { Stack } from "@mui/material";
import { Link } from "gatsby-theme-material-ui";
import * as React from "react";
import { useContext } from "react";
import { Marker } from "react-map-gl";

import StoreContext from "../context/StoreContext";
import { IMarker } from "../utils/common";
import MarkerPlain from "./icons/MarkerPlain";
import LPSIcon from "./LPSIcon";
import MarkerFlag from "./MarkerFlag";

const fill = {
  closed: "#BDBDBD",
  open: "#E66A6A",
  highlighted: "#BA2525",
};

interface Props extends IMarker {
  markerFlag?: {
    typeLabel: string;
    nonstop: boolean;
    closed: boolean;
    openingHours: string;
  };
}

function MarkerWrapper({
  id,
  lat,
  lng,
  state,
  type,
  markerFlag,
}: Props): JSX.Element {
  const { highlighted } = useContext(StoreContext);
  const newState = highlighted === id ? "highlighted" : state;
  return (
    <Marker
      latitude={lat}
      longitude={lng}
      anchor={markerFlag ? "bottom-left" : "bottom"}
      offset={markerFlag && [-17.5, 0]}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={0}
      >
        <MarkerPlain fill={fill[newState]} />
        <Link to={`/detail/${id}`}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0}
            width={30}
            height={30}
            borderRadius="100%"
            sx={{
              position: "absolute",
              top: 3,
              left: 4.9,
              backgroundColor: "background.paper",
            }}
          >
            <LPSIcon fill={fill[newState]} type={type} />
          </Stack>
        </Link>
        {markerFlag && (
          <MarkerFlag
            typeLabel={markerFlag.typeLabel}
            nonstop={markerFlag.nonstop}
            closed={markerFlag.closed}
            openingHours={markerFlag.openingHours}
          />
        )}
      </Stack>
    </Marker>
  );
}

export default MarkerWrapper;
