import * as React from "react";

import { IconProps } from "../../utils/common";

const FirstAidIcon: React.FC<IconProps> = ({
  width = "21",
  height = "18",
  fill = "white",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 18"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.03809 17.1777H17.9531C19.6318 17.1777 20.458 16.3691 20.458 14.6992V6.0332C20.458 4.37207 19.6318 3.55469 17.9531 3.55469H14.8154V2.5791C14.8154 1.14648 14.0244 0.43457 12.6006 0.43457H8.39062C7.02832 0.43457 6.17578 1.14648 6.17578 2.5791V3.55469H3.03809C1.36816 3.55469 0.533203 4.37207 0.533203 6.0332V14.6992C0.533203 16.3691 1.36816 17.1777 3.03809 17.1777ZM7.23047 2.50879C7.23047 1.82324 7.64355 1.43652 8.36426 1.43652H12.6182C13.3477 1.43652 13.7695 1.82324 13.7695 2.50879V3.55469H7.23047V2.50879ZM6.49219 11.1045V9.60156C6.49219 9.15332 6.7998 8.85449 7.24805 8.85449H9.00586V7.10547C9.00586 6.64844 9.30469 6.34961 9.75293 6.34961H11.2471C11.7041 6.34961 12.0029 6.64844 12.0029 7.10547V8.85449H13.7607C14.209 8.85449 14.5166 9.15332 14.5166 9.60156V11.1045C14.5166 11.5439 14.209 11.8516 13.7607 11.8516H12.0029V13.6006C12.0029 14.0576 11.7041 14.3652 11.2471 14.3652H9.75293C9.30469 14.3652 9.00586 14.0576 9.00586 13.6006V11.8516H7.24805C6.7998 11.8516 6.49219 11.5439 6.49219 11.1045Z"
        fill={fill}
      />
    </svg>
  );
};

export default FirstAidIcon;
