import * as React from "react";

import { IconProps } from "../../utils/common";

const HospitalIcon: React.FC<IconProps> = ({
  width = "31",
  height = "32",
  fill = "#E66A6A",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.353516 18.7363C0.353516 20.4355 1.51074 21.5635 3.20996 21.5635H9.8457V28.2139C9.8457 29.9131 10.9736 31.0557 12.6582 31.0557H18.3271C20.0264 31.0557 21.1543 29.9131 21.1543 28.2139V21.5635H27.79C29.4893 21.5635 30.6318 20.4355 30.6318 18.7363V13.0674C30.6318 11.3828 29.4893 10.2549 27.79 10.2549H21.1543V3.61914C21.1543 1.91992 20.0264 0.777344 18.3271 0.777344H12.6582C10.9736 0.777344 9.8457 1.91992 9.8457 3.61914V10.2549H3.19531C1.49609 10.2549 0.353516 11.3828 0.353516 13.0674V18.7363Z"
        fill={fill}
      />
    </svg>
  );
};

export default HospitalIcon;
