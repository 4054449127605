import { ReactNode, useEffect, useState } from "react";
import * as React from "react";

interface Props {
  children: ReactNode;
}

export function LocalizationProvider({ children }: Props): JSX.Element {
  const [iso, setIso] = useState<string>("cs");

  const changeLanguage = (language) => {
    setIso(language);
    window.localStorage.setItem("language", language);
  };

  const toggleLanguage = () => {
    setIso((oldIso) => {
      const newIso = oldIso === "cs" ? "en" : "cs";
      window.localStorage.setItem("language", newIso);
      return newIso;
    });
  };

  useEffect(() => {
    const storedLanguage = window.localStorage.getItem("language");
    if (storedLanguage) {
      changeLanguage(storedLanguage);
    } else {
      changeLanguage(
        "language" in navigator && navigator.language.substring(0, 2) === "en"
          ? "en"
          : "cs"
      );
    }
  }, []);

  return (
    <LocalizationContext.Provider value={{ toggleLanguage, iso }}>
      {children}
    </LocalizationContext.Provider>
  );
}

export interface ILocalizationProvider {
  toggleLanguage: () => void;
  iso: string;
}

const LocalizationContext = React.createContext<ILocalizationProvider>(null);

export default LocalizationContext;
