import { LPS } from "./common";

export const getLPSType = (type: string): LPS => {
  switch (type) {
    case "Praktické lékařství":
      return "general";
    case "Dětská":
      return "pediatrician";
    case "Zubní":
      return "dentist";
    case "Lékárna":
      return "pharmacy";
  }
};
