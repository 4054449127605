import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { Box } from "@mui/material";
import * as React from "react";

function SatelliteIcon(): JSX.Element {
  return (
    <Box
      width={29}
      height={29}
      bgcolor="background.default"
      borderRadius={1}
      boxShadow={2}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <MapOutlinedIcon color="primary" />
    </Box>
  );
}

export default SatelliteIcon;
