import { CancelRounded } from "@mui/icons-material";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import HealingOutlinedIcon from "@mui/icons-material/HealingOutlined";
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { Button, IconButton } from "gatsby-theme-material-ui";
import * as React from "react";

import guideModalQuery from "../staticQueries/guideModalQuery";
import GuideModalItem from "./GuideModalItem";
import PillsIconOutlined from "./icons/PillsIconOutlined";

interface HighlightedInfoProps {
  isMobile?: boolean;
  info: string;
}

const HighlightedInfo = ({isMobile, info}: HighlightedInfoProps): JSX.Element => (
  <Paper elevation={0} sx={{ bgcolor: "background.dialogHighlight" }}>
    <Stack
      direction={isMobile ? "column" : "row" }
      justifyContent="center"
      alignItems={isMobile ? "flex-start" : "center"}
      spacing={2}
      padding={2}
    >
      <AnnouncementOutlinedIcon color="primary"/>
      <Typography>{info}</Typography>
    </Stack>
  </Paper>
)

interface Props {
  open: boolean;
  onClose: () => void;
}

const icons = [
  <PersonOutlinedIcon key={0} sx={{ color: "primary.light" }}/>,
  <CreditCardOutlinedIcon key={1} sx={{ color: "primary.light" }}/>,
  <LocalAtmIcon key={2} sx={{ color: "primary.light" }}/>,
  <HealingOutlinedIcon key={3} sx={{ color: "primary.light" }}/>,
  <PillsIconOutlined key={4} sx={{ color: "primary.light" }}/>,
  <AssignmentOutlinedIcon key={5} sx={{ color: "primary.light" }}/>,
  <AssignmentOutlinedIcon key={6} sx={{ color: "primary.light" }}/>,
];

function GuideModal({ open, onClose }: Props): JSX.Element {
  const {
    guideModal: { heading, items, highlightedInfo },
    common: { close },
  } = guideModalQuery();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTabletOrDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="lg">
      <DialogTitle>
        {heading}
        <IconButton
          sx={{ position: "absolute", right: 5 }}
          onClick={onClose}
          aria-label="language switch"
        >
          <CancelRounded/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isMobile && (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            sx={{
              padding: "10px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            {items.map((item, key) => (
              <GuideModalItem
                key={key}
                label={item.label}
                labelBold={key < 3}
                description={item.description}
              >
                {icons[key]}
              </GuideModalItem>
            ))}
            <HighlightedInfo isMobile info={highlightedInfo}/>
          </Stack>
        )}
        {isTabletOrDesktop && (
          <>
            <Grid container spacing={4}>
              <Grid
                container
                alignItems="center"
                direction="column"
                item
                md={4}
              >
                <PersonOutlinedIcon sx={{ fontSize: 78 }} color="primary"/>
                <Typography fontSize="16px" fontWeight="bold">
                  {items[0].label}
                </Typography>
                <Typography fontSize="14px">{items[0].description}</Typography>
              </Grid>
              <Grid
                container
                alignItems="center"
                direction="column"
                item
                md={4}
              >
                <CreditCardOutlinedIcon sx={{ fontSize: 78 }} color="primary"/>
                <Typography fontSize="16px" fontWeight="bold">
                  {items[1].label}
                </Typography>
                <Typography fontSize="14px">{items[1].description}</Typography>
              </Grid>
              <Grid
                container
                alignItems="center"
                direction="column"
                item
                md={4}
              >
                <LocalAtmIcon sx={{ fontSize: 78 }} color="primary"/>
                <Typography fontSize="16px" fontWeight="bold">
                  {items[2].label}
                </Typography>
                <Typography fontSize="14px">{items[2].description}</Typography>
              </Grid>
            </Grid>
            <br/>
            <br/>
            <Divider/>
            <br/>
            <br/>
            <Grid container spacing={4}>
              {items.slice(3).map((item, key) => (
                <Grid item md={6} key={item.label}>
                  <GuideModalItem
                    label={item.label}
                    description={item.description}
                  >
                    {icons[key + 3]}
                  </GuideModalItem>
                </Grid>
              ))}
            </Grid>
            <br/>
            <br/>
            <HighlightedInfo info={highlightedInfo}/>
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button onClick={onClose}>{close}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default GuideModal;
