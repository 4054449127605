import { graphql, navigate, useStaticQuery } from "gatsby";
import * as React from "react";
import { ReactNode, useEffect, useState } from "react";

import { StoreLpscsvQuery } from "../graphqlTypes";
import { Checked, IMarker, LPS, LPSData } from "../utils/common";
import { LPSTypes } from "../utils/constants";
import { filterList } from "../utils/filterList";
import { getLPSState } from "../utils/getLPSState";
import { getLPSType } from "../utils/getLPSType";
import getMarker from "../utils/getMarker";
import { checkClosed } from "../utils/timeUtils";

interface Props {
  children: ReactNode;
}

export function StoreProvider({ children }: Props): JSX.Element {
  const [searchValue, setSearchValue] = useState("");

  const result = useStaticQuery<StoreLpscsvQuery>(graphql`
    query StoreLPSCSV {
      allLpsCsv {
        nodes {
          id
          provider
          address
          openDaily
          openHoliday
          openWeekend
          type
          latLng
        }
      }
    }
  `);
  const [listData, setListData] = useState<LPSData>(result.allLpsCsv.nodes);
  const [filteredListData, setFilteredListData] = useState(listData);
  const [listOpen, setListOpen] = useState(false);
  const [markers, setMarkers] = useState(
    result.allLpsCsv.nodes.map((item) => {
      return getMarker(item);
    })
  );
  const [filteredMarkers, setFilteredMarkers] = useState(markers);
  const [highlighted, setHighlighted] = useState("");
  const [checked, setChecked] = useState({
    general: true,
    pediatrician: true,
    dentist: true,
    pharmacy: true,
  });

  const updateSearchValue = (value: string) => setSearchValue(value);
  const updateListData = (data: LPSData) => setListData(data);
  const updateFilteredListData = (data: LPSData) => setFilteredListData(data);
  const toggleList = () => setListOpen((listOpen) => !listOpen);
  const openList = () => setListOpen(true);
  const updateMarkers = (newMarkers: IMarker[]) => setMarkers(newMarkers);
  const updateFilteredMarkers = (newMarkers: IMarker[]) =>
    setFilteredMarkers(newMarkers);
  const highlight = (markerId: string) => setHighlighted(markerId);
  const clearHighlighted = () => setHighlighted("");

  const filterData = () => {
    const checkedArr = LPSTypes.filter((item) => checked[item]);
    const tagFiltered = listData.filter((item) =>
      checkedArr.includes(getLPSType(item.type))
    );
    const searchFiltered = filterList(tagFiltered, searchValue.trim());
    const markers = searchFiltered.map((item) => {
      const latLng = item.latLng.split(",");
      return {
        id: item.id,
        lat: parseFloat(latLng[0].replace(/["/]/g, "")),
        lng: parseFloat(latLng[1].replace(/["/]/g, "")),
        state: getLPSState(
          checkClosed(item.openDaily, item.openHoliday),
          false
        ),
        type: getLPSType(item.type),
      };
    });

    updateFilteredListData(searchFiltered);
    updateFilteredMarkers(markers);
  };

  const toggleChecked = (type: LPS) => {
    setChecked((oldChecked) => {
      const newChecked = { ...oldChecked, [type]: !oldChecked[type] };
      void navigate(
        "/?tags=" +
          Object.entries(newChecked)
            .filter((item) => item[1])
            .map((item) => item[0])
            .join()
      );
      return newChecked;
    });
  };

  const updateChecked = (tags: string[]) => {
    setChecked({
      general: tags.includes("general"),
      pediatrician: tags.includes("pediatrician"),
      dentist: tags.includes("dentist"),
      pharmacy: tags.includes("pharmacy"),
    });
  };

  const resetChecked = () =>
    setChecked({
      general: true,
      pediatrician: true,
      dentist: true,
      pharmacy: true,
    });

  useEffect(filterData, [checked, listData]);

  return (
    <StoreContext.Provider
      value={{
        searchValue,
        updateSearchValue,
        filterData,
        listOpen,
        toggleList,
        openList,
        listData,
        updateListData,
        filteredListData,
        updateFilteredListData,
        markers,
        updateMarkers,
        filteredMarkers,
        updateFilteredMarkers,
        highlighted,
        highlight,
        clearHighlighted,
        checked,
        toggleChecked,
        updateChecked,
        resetChecked,
        lastMapPath:
          "/?tags=" +
          Object.entries(checked)
            .filter((item) => item[1])
            .map((item) => item[0])
            .join(),
      }}
    >
      {children}
    </StoreContext.Provider>
  );
}

interface IProvider {
  searchValue: string;
  updateSearchValue: (value: string) => void;
  filterData: () => void;
  listOpen: boolean;
  toggleList: () => void;
  openList: () => void;
  listData: LPSData;
  updateListData: (data: LPSData) => void;
  filteredListData: LPSData;
  updateFilteredListData: (data: LPSData) => void;
  markers: IMarker[];
  updateMarkers: (newMarkers: IMarker[]) => void;
  filteredMarkers: IMarker[];
  updateFilteredMarkers: (newMarkers: IMarker[]) => void;
  highlighted: string;
  highlight: (highlight: string) => void;
  clearHighlighted: () => void;
  checked: Checked;
  toggleChecked: (type: LPS) => void;
  updateChecked: (tags: string[]) => void;
  resetChecked: () => void;
  lastMapPath: string;
}

const StoreContext = React.createContext<IProvider>(null);

export default StoreContext;
