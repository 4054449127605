import BaseClient from "@mapbox/mapbox-sdk";
import DirectionClient from "@mapbox/mapbox-sdk/services/directions";
import MatrixClient from "@mapbox/mapbox-sdk/services/matrix";
import * as React from "react";
import { ReactNode, useContext } from "react";

interface Props {
  children: ReactNode;
}
interface IMapSDKContext {
  directionClient: DirectionClient;
  matrixClient: MatrixClient;
}

const MapSDKContext = React.createContext<IMapSDKContext>(undefined);

export default MapSDKContext;

const baseClient = BaseClient({
  accessToken: process.env.MAPBOX_API_ACCESS_TOKEN,
});

const directionClient = DirectionClient(baseClient);
const matrixClient = MatrixClient(baseClient);

export function useMapBoxSDK(): IMapSDKContext {
  return useContext(MapSDKContext);
}

export function MapSDKProvider({ children }: Props): JSX.Element {
  return (
    <MapSDKContext.Provider value={{ directionClient, matrixClient }}>
      {children}
    </MapSDKContext.Provider>
  );
}
