import { LPSData } from "./common";

//add tags

const undressString = (string: string) => {
  return string
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
};

export const filterList = (list: LPSData, phrase: string): LPSData => {
  if (phrase === "") return list;
  return list.filter((item) => {
    const newPhrase = undressString(phrase);
    return (
      undressString(item.provider).includes(newPhrase) ||
      undressString(item.type).includes(newPhrase) ||
      undressString(item.address).includes(newPhrase) ||
      (undressString("Všeobecná").includes(newPhrase) &&
        item.type === "Praktické lékařství")
    );
  });
};
